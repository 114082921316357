import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  sectionPaddings,
} from '../utilities';
import { Container, Heading, HTMLContent } from './ui';

const StyledBgImageContent = styled.section`
  ${sectionPaddings()};
`;

const StyledBgImageContentInner = styled.div`
  max-width: 360px;
`;

const StyledBgImageContentHeading = styled(Heading)`
  margin-bottom: 18px;

  ${minBreakpointQuery.small`
    margin-bottom: 22px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 26px;
  `}
`;

const BgImageContent = ({ heading, content }) => {
  return (
    <StyledBgImageContent>
      <Container>
        <StyledBgImageContentInner>
          <StyledBgImageContentHeading colour={brandColours.primary}>
            {heading}
          </StyledBgImageContentHeading>
          <HTMLContent content={content} />
        </StyledBgImageContentInner>
      </Container>
    </StyledBgImageContent>
  );
};

export default BgImageContent;
