import React from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import {
  fontSize,
  minBreakpointQuery,
  brandColours,
  standardColours,
  maxBreakpointQuery,
} from '../utilities';

const Wrapper = styled.div`
  ${minBreakpointQuery.mlarge`
    display: flex;
    border-bottom: 1px solid ${brandColours.primary};
  `}

  ${minBreakpointQuery.large`
    position: absolute;
    right: 0;
    bottom: 0;
    border-bottom: 0;
    background: ${standardColours.white};
    padding-right: 100px;
  `}
`;

const FeaturedCompetitionBlock = styled.div`
  padding: 30px 20px;
  background: ${brandColours.primary};
  color: ${standardColours.white};
  cursor: pointer;

  ${minBreakpointQuery.mlarge`
    display: flex;
    align-items: center;
    flex-basis: 33.33%;
    padding: 50px;
  `}

  ${minBreakpointQuery.large`
    padding: 55px;
  `}

  a {
    display: block;
    margin-top: 12px;
    ${fontSize(16)};
    line-height: 1.4;

    ${minBreakpointQuery.mlarge`
      margin-top: 20px;
    `}
  }
`;

const FeaturedCompetitionContent = styled.div``;

const Heading = styled.h2`
  white-space: nowrap;
  ${fontSize(16)};

  ${minBreakpointQuery.tiny`
    ${fontSize(18)};
  `}
`;

const UpcomingCompetitionsHeading = styled.div`
  ${minBreakpointQuery.mlarge`
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0 30px;
  `}

  ${minBreakpointQuery.large`
    padding: 0 55px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    padding: 0 70px;
  `}
`;

const FeaturedCompetitionTime = styled.p`
  margin-top: 6px;
  ${fontSize(14)};
  cursor: pointer;
  ${minBreakpointQuery.mlarge`
    margin-top: 10px;
    ${fontSize(16)};
    font-weight: 700;
  `}
`;

const UpcomingCompetitionsBlock = styled.div`
  background: ${standardColours.white};
  ${maxBreakpointQuery.mlarge`
    padding: 40px 20px;
  `}
  ${minBreakpointQuery.mlarge`
    display: flex;
    flex: 1;
    justify-content: space-around;
    padding: 25px 0;
    align-items: center;
  `}
  ${minBreakpointQuery.large`
    flex: unset;
    justify-content: flex-start;
    padding: 36px 0 28px;
  `}
`;

const UpcomingCompetitionsContent = styled.div``;

const UpcomingCompetitionsList = styled.ul`
  ${maxBreakpointQuery.mlarge`
    margin-top: 22px;
  `}
`;

const UpcomingCompetition = styled.li`
  padding: 15px 0;
  border-top: 1px solid ${brandColours.primary};
  cursor: pointer;
  &:first-child {
    padding-top: 0;
    border: 0;
  }

  ${minBreakpointQuery.mlarge`
    max-width: 280px;
    border-bottom: 1px solid ${brandColours.primary};
  `}

  ${minBreakpointQuery.large`
    max-width: 320px;
  `}
`;

const UpcomingCompetitionTime = styled.p`
  font-weight: 500;
  ${minBreakpointQuery.mlarge`
    margin-top: 2px;
    ${fontSize(14)};
  `}

  ${maxBreakpointQuery.mlarge`
    display: none;
  `}
`;

const UpcomingCompetitionsLink = styled(Link)`
  display: block;
  margin-top: 12px;
  ${fontSize(14)};
  font-weight: 500;
  color: ${brandColours.primary};

  ${minBreakpointQuery.mlarge`
    margin-top: 16px;
  `}
`;

const UpcomingCompetitions = ({ featuredCompetition, competitions }) => {
  return (
    (featuredCompetition || competitions.nodes.length > 0) && (
      <Wrapper>
        {featuredCompetition && (
          <FeaturedCompetitionBlock
            onClick={() =>
              navigate(`/competitions/${featuredCompetition.slug}`)
            }
          >
            <FeaturedCompetitionContent>
              <Heading white={true}>Featured Competition</Heading>
              <p>
                <Link to={`/competitions/${featuredCompetition.slug}`}>
                  {featuredCompetition.title}
                </Link>
              </p>
              <FeaturedCompetitionTime>
                <time dateTime={featuredCompetition.dateAttribute}>
                  {featuredCompetition.dateText}
                </time>
              </FeaturedCompetitionTime>
            </FeaturedCompetitionContent>
          </FeaturedCompetitionBlock>
        )}

        {competitions.nodes.length > 0 && (
          <UpcomingCompetitionsBlock>
            <UpcomingCompetitionsHeading>
              <Heading padding>Upcoming Competitions</Heading>
            </UpcomingCompetitionsHeading>
            <UpcomingCompetitionsContent>
              <UpcomingCompetitionsList>
                {competitions.nodes.map(
                  ({ id, title, slug, dateAttribute, dateText }) => (
                    <UpcomingCompetition
                      key={id}
                      onClick={() => navigate(`/competitions/${slug}`)}
                    >
                      <Link to={`/competitions/${slug}`}>{title}</Link>
                      <UpcomingCompetitionTime>
                        <time dateTime={dateAttribute}>{dateText}</time>
                      </UpcomingCompetitionTime>
                    </UpcomingCompetition>
                  )
                )}
              </UpcomingCompetitionsList>

              <UpcomingCompetitionsLink to="/competitions">
                View All Competitions
              </UpcomingCompetitionsLink>
            </UpcomingCompetitionsContent>
          </UpcomingCompetitionsBlock>
        )}
      </Wrapper>
    )
  );
};

export default UpcomingCompetitions;
