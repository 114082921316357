import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  standardColours,
  brandColours,
  fluidFontSize,
  fontWeights,
  standardTransition,
} from '../utilities';

const StyledCallToActionBlock = styled.article`
  position: relative;
  max-height: 260px;
  overflow: hidden;

  ${minBreakpointQuery.xxlarge`
    max-height: 360px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    max-height: 460px;
  `}
`;

const StyledCallToActionBlockImage = styled(Img)`
  min-width: 100%;
`;

const StyledCallToActionBlockContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.45);
  ${standardTransition('background-color')};

  &:hover {
    background-color: rgba(0, 0, 0, 0.65);
  }
`;

const StyledCallToActionBlockText = styled.p`
  max-width: 140px;
  color: ${standardColours.white};
  ${fluidFontSize(
    '20px',
    '30px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  font-weight: ${fontWeights.bold};
  text-align: center;

  &:after {
    content: '';
    display: block;
    margin-top: 12px;
    height: 6px;
    width: 100%;
    background-color: ${brandColours.primary};
  }
`;

const CallToActionBlock = ({
  data: {
    image,
    text,
    link: { slug },
  },
}) => {
  return (
    <StyledCallToActionBlock>
      <Link to={`/${slug}`}>
        <StyledCallToActionBlockImage fluid={image.fluid} alt={image.alt} />
        <StyledCallToActionBlockContent>
          <StyledCallToActionBlockText>{text}</StyledCallToActionBlockText>
        </StyledCallToActionBlockContent>
      </Link>
    </StyledCallToActionBlock>
  );
};

export default CallToActionBlock;
