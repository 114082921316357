import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery } from '../utilities';
import CallToActionBlock from '../components/CallToActionBlock';

const StyledCallToActionBlocks = styled.section`
  ${minBreakpointQuery.medium`
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
  `}
`;

const CallToActionBlocks = ({ items }) => {
  return (
    <StyledCallToActionBlocks>
      {items.map(item => (
        <CallToActionBlock key={item.id} data={item} />
      ))}
    </StyledCallToActionBlocks>
  );
};

export default CallToActionBlocks;
