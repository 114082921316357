import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../utilities';
import { Container, Heading, HTMLContent } from './ui';

const StyledImageContent = styled.section`
  ${sectionMargins()};
`;

const StyledImageContentInner = styled.div`
  ${minBreakpointQuery.small`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ flip }) => {
      if (flip) {
        return `
          flex-direction: row-reverse;
        `;
      }
    }}
  `}
`;

const StyledImageContentBlock = styled.div`
  ${minBreakpointQuery.small`
    width: calc(50% - 20px);
  `}

  ${minBreakpointQuery.medium`
    width: calc(50% - 30px);
  `}

  ${minBreakpointQuery.large`
    width: calc(50% - 40px);
  `}
`;

const StyledImageContentHeading = styled(Heading)`
  margin: 22px 0 18px;

  ${minBreakpointQuery.small`
    margin-top: 0;
    margin-bottom: 22px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 26px;
  `}
`;

const ImageContent = ({ heading, content, image, flip }) => {
  return (
    <StyledImageContent>
      <Container>
        <StyledImageContentInner flip={flip}>
          <StyledImageContentBlock>
            <Img fluid={image.fluid} alt={image.alt} />
          </StyledImageContentBlock>
          <StyledImageContentBlock>
            <StyledImageContentHeading>{heading}</StyledImageContentHeading>
            <HTMLContent content={content} />
          </StyledImageContentBlock>
        </StyledImageContentInner>
      </Container>
    </StyledImageContent>
  );
};

export default ImageContent;
