import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { standardColours, brandColours } from '../utilities';
import PostCards from './PostCards';

const LatestNews = ({
  data: {
    allDatoCmsNewsSingle: { nodes },
  },
}) => {
  return (
    nodes.length > 0 && (
      <PostCards
        heading={{
          text: 'Latest News',
          colour: `${standardColours.white}`,
          align: 'center',
        }}
        items={nodes}
        baseSlug="/news/"
        link={{
          slug: '/news',
          text: 'View all News',
          alt: 'true',
        }}
        flipCards={true}
        paddings={true}
        backgroundColour={brandColours.tertiary}
      />
    )
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query LatestNewsQuery {
        allDatoCmsNewsSingle(
          limit: 3
          sort: { fields: meta___firstPublishedAt, order: DESC }
        ) {
          nodes {
            id
            title
            slug
            featuredImage {
              fluid(
                maxHeight: 280
                maxWidth: 480
                imgixParams: {
                  auto: "compress"
                  fit: "crop"
                  h: "280"
                  w: "480"
                }
              ) {
                ...GatsbyDatoCmsFluid
              }
              alt
            }
            meta {
              firstPublishedAtText: firstPublishedAt(
                formatString: "DD MMMM YYYY"
              )
              firstPublishedAtAttribute: firstPublishedAt(
                formatString: "YYYY-MM-DD"
              )
            }
          }
        }
      }
    `}
    render={data => <LatestNews data={data} {...props} />}
  />
);
