import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import React from 'react';
import styled from 'styled-components';
import Banner from '../components/Banner';
import UpcomingCompetitions from '../components/UpcomingCompetitions';
import Layout from '../components/Layout';
import BgImageContent from '../components/BgImageContent';
import CallToActionBlocks from '../components/CallToActionBlocks';
import UpcomingEvents from '../components/UpcomingEvents';
import LatestNews from '../components/LatestNews';
import ImageContent from '../components/ImageContent';

const BannerCompetitionsWrap = styled.div`
  position: relative;
`;

const HomePageTemplate = ({
  data: {
    home: {
      seoMetaTags,
      title,
      bannerLinkText,
      bannerLinkPage,
      bannerImage,
      introductionHeading,
      introductionContent,
      callToActionBlocks,
      imageContent,
    },
    featuredCompetition,
    competitions,
    events,
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <BannerCompetitionsWrap>
        <Banner
          title={title}
          linkPage={bannerLinkPage}
          linkText={bannerLinkText}
          image={bannerImage}
        />
        <UpcomingCompetitions
          featuredCompetition={featuredCompetition.featuredCompetition}
          competitions={competitions}
        />
      </BannerCompetitionsWrap>
      <BgImageContent
        heading={introductionHeading}
        content={introductionContent}
      />
      <CallToActionBlocks
        items={callToActionBlocks[0].instance.callToActionBlocks}
      />
      <UpcomingEvents events={events.nodes} />
      <LatestNews />
      <ImageContent
        heading={imageContent[0].heading}
        content={imageContent[0].content}
        image={imageContent[0].image}
        flip={imageContent[0].flip}
      />
    </main>
  </Layout>
);

export const HomePageTemplateQuery = graphql`
  query HomePageTemplateQuery(
    $todaysDate: Date!
    $featuredCompetitionId: String!
  ) {
    home: datoCmsHome {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerLinkPage {
        slug
      }
      bannerLinkText
      bannerImage {
        fluid(
          maxHeight: 680
          maxWidth: 1600
          imgixParams: { auto: "compress", fit: "crop", h: "680", w: "1600" }
        ) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      introductionHeading
      introductionContent
      callToActionBlocks {
        instance {
          callToActionBlocks {
            id
            image {
              fluid(
                maxHeight: 400
                maxWidth: 600
                imgixParams: {
                  auto: "compress"
                  fit: "crop"
                  h: "400"
                  w: "600"
                }
              ) {
                ...GatsbyDatoCmsFluid
              }
              alt
            }
            text
            link {
              ... on DatoCmsHome {
                slug
              }
              ... on DatoCmsPage {
                slug
              }
              ... on DatoCmsCompetitionsArchive {
                slug
              }
              ... on DatoCmsCompetitionSingle {
                slug
              }
              ... on DatoCmsEventsArchive {
                slug
              }
              ... on DatoCmsEventSingle {
                slug
              }
              ... on DatoCmsNewsArchive {
                slug
              }
              ... on DatoCmsNewsSingle {
                slug
              }
            }
          }
        }
      }
      imageContent {
        heading
        content
        image {
          fluid(
            maxHeight: 680
            maxWidth: 600
            imgixParams: { auto: "compress", fit: "crop", h: "680", w: "600" }
          ) {
            ...GatsbyDatoCmsFluid
          }
          alt
        }
        flip
      }
    }
    featuredCompetition: datoCmsCompetitionsArchive {
      featuredCompetition {
        dateAttribute: date(formatString: "YYYY-MM-DD")
        dateText: date(formatString: "DD MMMM YYYY")
        id
        slug
        title
      }
    }
    competitions: allDatoCmsCompetitionSingle(
      filter: { date: { gte: $todaysDate }, id: { ne: $featuredCompetitionId } }
      limit: 2
      sort: { order: ASC, fields: date }
    ) {
      nodes {
        dateAttribute: date(formatString: "YYYY-MM-DD")
        dateText: date(formatString: "DD MMMM YYYY")
        id
        slug
        title
      }
    }
    events: allDatoCmsEventSingle(
      filter: { date: { gte: $todaysDate } }
      limit: 3
      sort: { fields: date, order: ASC }
    ) {
      nodes {
        id
        title
        slug
        featuredImage {
          fluid(
            maxHeight: 280
            maxWidth: 480
            imgixParams: { auto: "compress", fit: "crop", h: "280", w: "480" }
          ) {
            ...GatsbyDatoCmsFluid
          }
          alt
        }
        dateText: date(formatString: "DD MMMM YYYY")
        dateAttribute: date(formatString: "YYYY-MM-DD")
      }
    }
  }
`;

export default HomePageTemplate;
