import React from 'react';
import PostCards from './PostCards';

const UpcomingEvents = ({ events }) => {
  return (
    events.length > 0 && (
      <PostCards
        heading={{
          text: 'Upcoming Events',
          align: 'center',
        }}
        items={events}
        baseSlug="/events/"
        link={{
          slug: '/events',
          text: 'View all Events',
        }}
        margins={true}
      />
    )
  );
};

export default UpcomingEvents;
